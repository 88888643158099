import React from "react";
import styled from "@emotion/styled";
import { ThemeContext } from "../../contexts/Context";
import MenuOptions from "./MenuOptions";
import { navigate } from '@reach/router';
import { IoMdClose } from "react-icons/io";

const Modal = ({
  show,
  setShow,
  recommendedArray,
  setRecommendedArray,
  menuSelectionFormRef,
  addNewItemToCart,
  isCheckout
}) => {
  //State
  const displayShow = show ? "flex" : "none";
  const themeContext = React.useContext(ThemeContext);

  const modalContent = (recommendedArray) => {
    return (
      <React.Fragment>
        <MenuOptions
          visibleSlide={1}
          key={recommendedArray[0].optionTitle + "recommendedModal"}
          menuIndex={0}
          menuSelectionFormRef={menuSelectionFormRef}
          menuOptions={recommendedArray[0]}
          setRecommendedArray={setRecommendedArray}
          recommendedArray={recommendedArray}
          setPrice={() => { }}
          isSetMeal={true}
          fixedPrice={false}
          hasRecommended={true}
          isModal={true}
        />
      </React.Fragment>
    );
  };

  const modalButton = () => {
    return (
      <ModalButtonContainer>
        <ModalConfirmButton
          themeContext={themeContext}
          onClick={() => {
            setShow(false);
            setRecommendedArray(null);
            if (isCheckout) navigate('/checkout');
          }}
        >
          <ModalText
            fontSize={16}
            fontWeight={600}
            color={themeContext.fontPrimary}
          >
            No Thanks!
          </ModalText>
        </ModalConfirmButton>
        <ModalConfirmButton
          themeContext={themeContext}
          backgroundColor={themeContext.fontPrimary}
          onClick={() => {
            addNewItemToCart(
              recommendedArray[0].options
            );
            if (isCheckout) navigate('/checkout');
          }}
        >
          <ModalText
            fontSize={16}
            fontWeight={600}
            color={themeContext.cartFont}
          >
            Add to Cart
          </ModalText>
        </ModalConfirmButton>
      </ModalButtonContainer>
    );
  };

  return (
    <ModalContainer display={displayShow}>
      <ModalMain themeContext={themeContext}>
        <ModalTitle themeContext={themeContext}>
          <ModalText
            fontSize={19}
            fontWeight={500}
            color={themeContext.unselectedCategory}
            margin={10}
          >
            Would you like any add-ons?
          </ModalText>
          <DeleteButton
            themeContext={themeContext}
            onClick={() => {
              setShow(false);
              setRecommendedArray(null);
              if (isCheckout) navigate('/checkout');
            }}
          >
            <IoMdClose size={22} />
          </DeleteButton>
        </ModalTitle>
        <ModalContent themeContext={themeContext}>
          {modalContent(recommendedArray)}
        </ModalContent>
        {modalButton()}
      </ModalMain>
    </ModalContainer>
  );
};

export default Modal;

const ModalContainer = styled.div`
  display: ${(props) => props.display};
  position: fixed;
  justify-content: center;
  align-items: center;
  top: 0;

  width: 100vw;
  max-width: 550px;
  height: 100%;
  background: rgba(0, 0, 0, 0.6);
  z-index: 100;
`;

const ModalMain = styled.div`
  border-radius: 5px 5px 0 0;
  position: relative;
  justify-content: center;
  // max-height: 500px;
  width: 100vw;
  max-width: 550px;
  font-size: 20px;
  font-weight: 500;
  font-family: ${(props) => props.themeContext.fontFamily};
  background-color: #fff;
  color: black;
  z-index: 10;
  position: absolute;
  left: 0;
  bottom: 0;

  animation: modalRec .2s;
  animation-timing-function: ease-out;
  @keyframes modalRec {
    0%  {
      transform: translateY(350px);
    }
    
    100%  {
      transform: translateY(0px);
    }
    
  }
`;

const ModalTitle = styled.div`
  display: flex;
  background: ${(props) => props.themeContext.secondaryColor};
  border-radius: 5px 5px 0 0;
  padding: 10px 15px;
  height: 100%;
  align-self: center;
  justify-content: space-between;
`;
const ModalText = styled.div`
  color: ${(props) => (props.color ? props.color : "black")};
  font-size: ${(props) => props.fontSize}px;
  font-weight: ${(props) => props.fontWeight};
  line-height: 30px;
`;
const DeleteButton = styled.div`
  display: grid;
  align-self: center;
  place-content: center;
  color: white;
  background-color: ${(props) => props.themeContext.selectedColor};
  width: 30px;
  height: 30px;
  border-radius: 15px;
`;

const ModalContent = styled.div`
  overflow: scroll;
  padding: 20px 10px 10px;
  max-height: 400px;
  text-align: left;
`;
const ModalButtonContainer = styled.div`
  display: flex;
  flex: 1;
  flex-direction: row;
  width: 100%;
  text-align: center;
`;

const ModalConfirmButton = styled.div`
  flex: 1;
  background-color: ${(props) => props.backgroundColor};
  color: white;
  margin: 6%;
  margin-top: 8px;
  padding: 10px 0;
  line-height: 20px;
  max-height: 30px;
  text-align: center;
  border-radius: 6px;
  border: 2px solid;
  border-color: ${(props) => props.themeContext.fontPrimary};
`;



import React, { useEffect, useState } from "react";
import styled from "@emotion/styled";
import { ThemeContext, LanguageContext, UserContext, ActionContext } from "../contexts/Context";
import axios from "axios";
import { IoCallOutline, IoGiftOutline, IoCashOutline } from "react-icons/io5";
import Modal from '../newcomponents/optioncomponents/Modal';
import CusRegister from '../newcomponents/customerComponents/CustomerEdit';

const CustomerDetail = ({ show, setShow }) => {
    const [displayShow, setDisplay] = useState(show ? "grid" : "none");
    const [campaign, setCampaign] = useState({});
    const [loyalty, setLoyalty] = useState(0);
    const { addCustomer } = React.useContext(ActionContext);
    const themeContext = React.useContext(ThemeContext);
    const languageContext = React.useContext(LanguageContext);
    const { lanIndex, token } = React.useContext(UserContext);
    const { customer, promotion } = React.useContext(UserContext);
    const [showRegister, setShowRegister] = useState(false);
    const [processing, setProcessing] = useState(false);
    const [showError, setShowError] = useState(false);
    const [error, setError] = useState([]);

    useEffect(() => {
        if (token) {
            axios.get(`/v2/api/customer/reward`, {
                headers: { Authorization: "Bearer " + token },
            })
                .then(function (response) {
                    console.log(response.status);
                    if (response.status == 200) {
                        for (let c of response.data) {
                            if (c.type == 0) {
                                campaign[c._id] = {
                                    type: 0,
                                    description: c.rewardTitle,
                                    value: c.redeemPts,
                                    isValid: loyalty >= c.redeemPts
                                };
                            }
                        }
                        setCampaign(JSON.parse(JSON.stringify(campaign)));
                    }

                })
                .catch(function (response) {
                    //show warning

                })
        }
    }, []);

    const submitRegister = (customer) => {
        if (processing) return;
        if (token) {
            setProcessing(true);
            axios.put(`/v2/api/customer`,
                customer,
                {
                    headers: { Authorization: "Bearer " + token },
                })
                .then(function (response) {
                    setProcessing(false);
                    if (response.status == 200) {
                        setShowRegister(false);
                        addCustomer(response.data.customer);
                        setError([{ errorMessage: "Update Successfully" }]);
                        setShowError(true);
                    }

                })
                .catch(function (error) {
                    //show warning
                    setProcessing(false);
                    if (error.response && error.response.status == 402) {
                        console.log("RETURN catch");
                        setError([{ errorMessage: "Phone No has been used" }]);
                        setShowError(true);
                    } else {
                        console.log("RETURN catch");
                        setError([{ errorMessage: "Something went wrong. Please try again" }]);
                        setShowError(true);
                    }
                })
        }
    };

    useEffect(() => {
        if (!customer) return;

        let availPts = customer.rewardPts - customer.redeemPts - (customer.forfeit ? customer.forfeit : 0);
        setLoyalty(availPts);
        if (customer.otherRecords) {
            for (let o of customer.otherRecords) {
                campaign[o._id] = {
                    type: o.type,
                    description: o.title + " (" + (o.trans % o.minTrans) + "/" + o.minTrans + ")",
                    value: o.avail - o.redeem - o.forfeit,
                    isValid: o.avail - o.redeem - o.forfeit > 0
                };
            }
            setCampaign(JSON.parse(JSON.stringify(campaign)));
        }
    }, [customer]);

    if (!customer) {
        return <></>;
    }
    return (
        <React.Fragment>
            <ModalContainer>
                <ContentWrapper>
                    <HeaderTitle>{languageContext[lanIndex].memberInfo}</HeaderTitle>
                    <Container>
                        <Subtitle>
                            {languageContext[lanIndex].name}
                        </Subtitle>
                        <Title>
                            {`${customer.customerName}`}
                        </Title>
                    </Container>
                    <Container>
                        <ItemContainer>
                            <IoCallOutline size={24} />
                            <TextWrapper>
                                <Subtitle>
                                    {languageContext[lanIndex].phone}
                                </Subtitle>
                                <Title style={{ marginLeft: 4 }}>
                                    {`${customer.phone}`}
                                </Title>
                            </TextWrapper>
                        </ItemContainer>
                    </Container>
                    <PContainer>
                        {customer.topUpAmount - customer.topUpRedeemAmt > 0 &&
                            <PItemContainer>
                                <IoCashOutline size={24} />
                                <TextWrapper>
                                    <Subtitle>
                                        {languageContext[lanIndex].topUpAmount}
                                    </Subtitle>
                                    <Title>
                                        {`${(customer.topUpAmount - customer.topUpRedeemAmt).toFixed(2)}`}
                                    </Title>
                                </TextWrapper>
                            </PItemContainer>
                        }
                        <PItemContainer>
                            <IoGiftOutline size={24} />
                            <TextWrapper>
                                <Subtitle>
                                    {languageContext[lanIndex].loyalty}
                                </Subtitle>
                                <Title>
                                    {`${loyalty ? loyalty.toFixed(2) : "-"}`}
                                </Title>
                            </TextWrapper>
                        </PItemContainer>
                    </PContainer>
                    <Title style={{ marginTop: 16, marginBottom: 4 }}>Available Rewards</Title>
                    <CContainer>
                        {campaign && Object.keys(campaign).map((c, i) =>
                            <CampaignWrapper onClick={() => { }}
                                key={i}
                                isValid={campaign[c].isValid}>
                                <CType type={campaign[c].type}>
                                    {campaign[c].type == 0 ? "Loyalty Pts" : campaign[c].type == 3 ? "Stamp" : "Voucher"}
                                </CType>
                                <Description>
                                    {campaign[c].description}
                                </Description>
                                <Value>
                                    {campaign[c].value}
                                </Value>
                            </CampaignWrapper>
                        )}
                    </CContainer>
                    <Disclaimer>
                        **{languageContext[lanIndex].rewardDisclaimer}**
                    </Disclaimer>
                    <ButtonWrapper>
                        <Button themeContext={themeContext} onClick={() => {
                            setShowRegister(true);
                        }} >
                            {languageContext[lanIndex].edit}
                        </Button>

                    </ButtonWrapper>
                </ContentWrapper>
            </ModalContainer>
            <Modal show={showError} setShow={setShowError} error={error} />
            <CusRegister
                show={showRegister}
                setShow={setShowRegister}
                setShowError={setShowError}
                setError={setError}
                onSubmit={submitRegister} />

        </React.Fragment >
    );
}

export default CustomerDetail;
const Disclaimer = styled.div`
    font-size: 12px;
    margin-top: 8px;
`;
const HeaderTitle = styled.div`
    font-size: 24px;
    font-weight: 500;
`;
const Container = styled.div`
    width: 100%;
    margin-bottom: 8px;
`;

const Subtitle = styled.div`
    font-size: 12px;
    font-weight: 300;
`;
const Title = styled.div`
    font-size: 16px;
    font-weight: 400;
`;
const TextWrapper = styled.div`
    margin-left: 8px;
`;
const PItemContainer = styled.div`
    display: flex;
    align-items: center;
    width: 100%;
    padding: 8px;
    margin: 4px;
    border: 1px solid;
    border-radius: 8px;
`;
const ItemContainer = styled.div`
    display: flex;
    align-items: flex-end;
    width: 45%;
    padding: 8px;

`;
const PContainer = styled.div`
    display: flex;
    width: 100%;
`;
const CContainer = styled.div`
    flex-grow:1;
    width: 100%;
    display: flex;
    overflow-y: auto;
    flex-direction: column;
`;
const CampaignWrapper = styled.div`
    display: flex;
    position: relative;
    width: 95%;
    padding: 16px 4px;
    border-radius: 8px;
    box-shadow: 0px 1px 3px rgba(0,0,0,0.4);
    margin: 0px 4px;
    margin-top: 16px;
    opacity:${(props) => props.isValid ? 1 : 0.4};
`;
const CType = styled.div`
    position: absolute;
    top: -8px;
    left: -4px;
    color: white;
    padding: 2px 8px;
    border-radius: 4px;
    font-size: 12px;
    box-shadow: 0px 1px 3px rgba(0,0,0,0.4);
    background: ${(props) => {
        switch (props.type) {
            case 0:
                return "#4caf50";
            case 1:
            case 3:
                return "#f44336";
            case 2:
                return "#2986cc";
        }
    }};
`;
const Description = styled.div`
    flex-grow:1;
`;
const Value = styled.div`
    padding: 0px 8px;
    font-size: 16px;
    font-weight: 500;
`;
const ButtonWrapper = styled.div`
    display: flex;
    width: 100%;
    justify-content: flex-end;
`;
const Button = styled.div`
    display: flex;
    width: 120px;
    margin: 8px;
    justify-content: center;
    align-items: center;
    font-size: 18px;
    font-weight: 400;
    border-radius: 25px;
    line-height: 40px;
    box-shadow: 0px 1px 3px rgba(0,0,0,0.3);
   
`;

const IconButton = styled.div`
  position: absolute;
  right: 16px;
  top: 16px;
  background: white;
  z-index: 99;
`;
const ContentWrapper = styled.div`
    display: flex;
    padding: 16px;
    flex-direction: column;
    align-items: center;
    flex-grow:1;
    overflow-y: auto;
    margin-top: 0px;
`;
const ModalContainer = styled.div`
    display: flex;
    flex-direction: column;
    background: #fff;
    top: 0;

    width: 100vw;
    max-width: 550px;
    height: 100dvh;
    z-index: 9;

    animation: modalOrder .1s;
    animation-timing-function: ease-out;
    @keyframes modalOrder {
        0%  {
        transform: translateY(350px);
        opacity: 0;
        }
        
        100%  {
        transform: translateY(0px);
        opacity: 1;
        }
        
    }
  `;


import React, { useRef, useEffect, useState } from "react";
import styled from "@emotion/styled";
import { ThemeContext, LanguageContext, UserContext, ActionContext } from "../../contexts/Context";
import axios from "axios";
import { IoCloseCircle } from "react-icons/io5";
import Modal from '../optioncomponents/Modal';
import CusPdpa from './CustomerPdpa';
import CusRegister from "./CustomerRegister";
import CusForgot from './CustomerForgot';

// import { customer } from '../../screens/customer';

const CustomerLogin = ({ show, setShow, handleCallback }) => {
    const [displayShow, setDisplay] = useState(show ? "grid" : "none");
    const themeContext = React.useContext(ThemeContext);
    const languageContext = React.useContext(LanguageContext);
    const { addCustomer, addPromotion } = React.useContext(ActionContext);
    const { lanIndex, token, userId, company, landingImg, allowRegister, promotion, hideOptionalField } = React.useContext(UserContext);
    const [phone, setPhone] = useState("");
    const [pin, setPin] = useState("");
    const [warningMsg, setWarningMsg] = useState("");
    const [error, setError] = useState([]);
    const [showError, setShowError] = useState(false);
    const [showPdpa, setShowPdpa] = useState(false);
    const [showRegister, setShowRegister] = useState(false);
    const [showForgot, setShowForgot] = useState(false);
    const [processing, setProcessing] = useState(false);
    useEffect(() => {
        setDisplay(show ? "flex" : "none");

    }, [show]);

    const submitRegister = (customer) => {
        if (processing) return;
        if (token && userId) {
            setProcessing(true);
            axios.post(`/v2/api/customer`,
                customer,
                {
                    headers: { Authorization: "Bearer " + token },
                })
                .then(function (response) {
                    setProcessing(false);
                    console.log(response.status);
                    if (response.status == 200) {
                        setShowRegister(false);
                        addCustomer(response.data.customer);

                        if (promotion && promotion.length > 0) {
                            let pm = JSON.parse(JSON.stringify(promotion));
                            pm = pm.concat(response.data.customer.promotion);
                            pm.sort((a, b) => (a.eventPriority < b.eventPriority) ? 1 : -1);
                            localStorage.setItem("promotion", JSON.stringify(pm));
                            addPromotion(pm);
                        } else if (response.data.customer.promotion && response.data.customer.promotion) {
                            let pm = response.data.customer.promotion;
                            localStorage.setItem("promotion", JSON.stringify(pm));
                            addPromotion(pm);
                        }
                        localStorage.setItem("customer", JSON.stringify(response.data.customer));
                        setError([{ errorMessage: "Register Successfully" }]);
                        setShowError(true);
                        if (handleCallback)
                            handleCallback();
                        setShow(false);
                    }

                })
                .catch(function (error) {
                    //show warning
                    setProcessing(false);
                    if (error.response && error.response.status == 402) {
                        console.log("RETURN catch");
                        setError([{ errorMessage: "Phone No has been used" }]);
                        setShowError(true);
                    } else {
                        console.log("RETURN catch");
                        setError([{ errorMessage: "Something went wrong. Please try again" }]);
                        setShowError(true);
                    }
                })
        }
    };

    const memberSubmit = () => {
        if (phone == "") {
            setWarningMsg("Phone No is required");
            return;

        } else if (!hideOptionalField && pin == "") {
            setWarningMsg("Pin is required");
            return;
        }
        if (processing) return;
        setProcessing(true);
        if (token && userId) {
            axios.post(`/v2/api/customer/login`,
                {
                    userId: userId,
                    phone: phone,
                    password: pin
                },
                {
                    headers: { Authorization: "Bearer " + token },
                })
                .then(function (response) {
                    setProcessing(false);
                    console.log(response.status);
                    if (response.status == 200) {
                        addCustomer(response.data);

                        if (promotion && promotion.length > 0) {
                            let pm = JSON.parse(JSON.stringify(promotion));
                            pm = pm.concat(response.data.promotion);
                            pm.sort((a, b) => (a.eventPriority < b.eventPriority) ? 1 : -1);
                            localStorage.setItem("promotion", JSON.stringify(pm));
                            addPromotion(pm);
                        } else if (response.data.promotion && response.data.promotion.length > 0) {
                            let pm = response.data.promotion;
                            localStorage.setItem("promotion", JSON.stringify(pm));
                            addPromotion(pm);
                        }

                        localStorage.setItem("customer", JSON.stringify(response.data));
                        if (handleCallback)
                            handleCallback();
                        setShow(false);
                    }

                })
                .catch(function (error) {
                    //show warning
                    console.log(error);
                    setProcessing(false);
                    console.log("RETURN catch");
                    if (error.response && error.response.status == 401) {
                        setError([{ errorMessage: "Invalid phone no or password" }]);
                        setShowError(true);
                    } else {
                        console.log("RETURN then");
                        setError([{ errorMessage: "Something went wrong. Please try again" }]);
                        setShowError(true);
                    }
                })


        }
    }

    const forgotPwd = (e) => {
        setShowForgot(true);
    }

    const register = (e) => {
        setShowRegister(true);
    }
    const handleChange = (e, name) => {
        if (name == 'phone') {
            setPhone(e);
        } else {
            setPin(e);
        }
        setWarningMsg("");
    }

    return (
        //phone, name, email, dob, gender password
        <React.Fragment>
            <ModalContainer display={displayShow}>
                <IconButton onClick={() => setShow(false)}>
                    <IoCloseCircle size={36} />
                </IconButton>
                <ContentWrapper>
                    <ImgWrapper image={landingImg || company.companyLogo} />
                    <WarningMsg themeContext={themeContext}>
                        {warningMsg}
                    </WarningMsg>
                    <InputWrapper>
                        <ModalContentText
                            fontWeight={400}
                            color={"black"}
                        >
                            {languageContext[lanIndex].phone}
                        </ModalContentText>
                        <PhoneWrapper>
                            <Input width={100} value={phone} onChange={(e) => { handleChange(e.target.value, 'phone') }} placeholder="e.g. 91231111" />
                        </PhoneWrapper>
                    </InputWrapper>
                    {!hideOptionalField &&
                        <InputWrapper>
                            <ModalContentText
                                fontWeight={400}
                                color={"black"}
                            >
                                Password
                            </ModalContentText>
                            <PhoneWrapper>
                                <Input type="password" width={100} value={pin} onChange={(e) => { handleChange(e.target.value, 'pin') }} style={{ paddingRight: 24 }} />
                            </PhoneWrapper>
                        </InputWrapper>
                    }
                    <InputWrapper>
                        <LoginButton themeContext={themeContext} onClick={memberSubmit} >
                            {languageContext[lanIndex].login}
                        </LoginButton>
                    </InputWrapper>

                    <InputWrapper style={{ flexDirection: "row", alignItems: 'center' }}>
                        {allowRegister &&
                            <FgButton onClick={register}>
                                {languageContext[lanIndex].register}
                            </FgButton>
                        }
                        {allowRegister &&
                            <Divider>|</Divider>
                        }
                        <FgButton onClick={forgotPwd}>
                            {languageContext[lanIndex].forgetPwd}
                        </FgButton>
                    </InputWrapper>
                </ContentWrapper>

            </ModalContainer>
            <Modal show={showError} setShow={setShowError} error={error} />
            <CusRegister
                show={showRegister}
                setShow={setShowRegister}
                setShowError={setShowError}
                setError={setError}
                onSubmit={submitRegister}
                setShowPdpa={setShowPdpa} />
            <CusPdpa
                show={showPdpa}
                setShow={setShowPdpa}
                setShowError={setShowError}
                setError={setError}
            />
            <CusForgot
                show={showForgot}
                setShow={setShowForgot}
                setShowError={setShowError}
                setError={setError}
            />
        </React.Fragment >
    );
}

export default CustomerLogin;

const WarningMsg = styled.div`
    color: ${(props) => props.themeContext.fontAlert};
`;

const LoginButton = styled.div`
    display: flex;
    width: 60%;
    margin: 8px auto;
    justify-content: center;
    align-items: center;
    font-size: 18px;
    font-weight: 400;
    border-radius: 25px;
    line-height: 40px;
    box-shadow: 0px 1px 3px rgba(0,0,0,0.3);
   
`;

const FgButton = styled.div`
    margin-top: 16px;
    font-size: 12px;
    text-decoration: underline;
`;
const Divider = styled.div`
    margin-top: 16px;
    margin-left: 8px;
    margin-right: 8px;
`;
const ImgWrapper = styled.div`
    width: 30%;
    height: 20%;
    margin-top: 10%;
    background-image: url(${(props) => `https://ireachfnb.com${props.image}` || '/toppings/toppings.jpg'});
    /*background-image: url(${(props) => `https://newdemo.chachingfnb.com${props.image}` || '/toppings/toppings.jpg'});*/

    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
`;
const PhoneWrapper = styled.div`
    display: flex;
    width: 80%;
`;
const InputWrapper = styled.div`
    display: flex;
    width: 100%;
    flex-direction: column;
    align-items: center;
    justify-content: center;
`;

const Input = styled.input`
  height: 30px;
  border-radius: 4px;
  padding-left: 16px;
  font-size: 16px;
  border: 1px solid #aaa;
  margin-bottom: 10px;
  width: ${(props) => props.width}%;
`;

const IconButton = styled.div`
  position: absolute;
  right: 16px;
  top: 16px;
  background: white;
  z-index: 99;
`;
const ModalContentText = styled.div`
    width: 80%;
`;
const ContentWrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    flex-grow:1;
    overflow: auto;
`;

const ModalContainer = styled.div`
    display: ${(props) => props.display};
    position: fixed;
    flex-direction: column;
    background: #fff;
    top: 0;

    width: 100vw;
    max-width: 550px;
    height: 100dvh;
    z-index: 8;

    animation: modalOrder .1s;
    animation-timing-function: ease-out;
    @keyframes modalOrder {
        0%  {
        transform: translateY(350px);
        opacity: 0;
        }
        
        100%  {
        transform: translateY(0px);
        opacity: 1;
        }
        
    }
  `;


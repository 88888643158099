/* eslint-disable no-console */
import React, { useState, useEffect, useRef } from "react";

import { Link, navigate } from "@reach/router";
import styled from "@emotion/styled";
import useWindowDimensions from "../hooks/useWindowDimensions";
import axios from "axios";
import moment from 'moment';
import Modal from "../newcomponents/optioncomponents/Modal";

import CheckoutPanel from "../newcomponents/CheckoutPanel";
import ConfirmCheckOutModal from '../newcomponents/optioncomponents/ConfirmCheckoutModal';
import TakeAwayModal from '../newcomponents/optioncomponents/TakeAwayModal';
import DeliveryOrNotModal from '../newcomponents/optioncomponents/DeliveryOrNotModal';
import PaymentTypeModal from '../newcomponents/optioncomponents/PaymentTypeModal'
import { IoIosArrowBack } from "react-icons/io";
import promo from '../newcomponents/customerComponents/Promotion';
import { UserContext, ActionContext, ThemeContext, LanguageContext } from "../contexts/Context";
import EditItemModal from '../newcomponents/EditItemModal';
import TableInfo from '../newcomponents/CheckOutInfo/TableInfo';
import ReserveInfo from "../newcomponents/CheckOutInfo/ReserveInfo";
import VoucherField from '../newcomponents/customerComponents/VoucherField';
import Loader from "react-loader-spinner";


const Checkout = () => {
  const [show, setShow] = useState(false); //warning dialog
  const [showConfirm, setShowConfirm] = useState(false); //display dialog to show submit confirmation
  const [showTk, setShowTk] = useState(false); //display dialog to input cusomter detail
  const [showDelive, setShowDelive] = useState(false); //display dialog to choose delivery or pickup
  //0 is TakeAway  1 is Dine in
  const [deliveryType, setDeliveryType] = useState(0); //user select delivery or pickup or dinein
  const [deliveryPrice, setDeliveryPrice] = useState(0); //display delivery | pickup option
  const [showPayment, setShowPayment] = useState(false); //display dialog to choose paynow or later
  const [payType, setPayType] = useState(-1); //user select paynow or pay later
  const [error, setError] = useState([]);
  const [showEdit, setShowEdit] = useState(false);
  const [editIndex, setEditIndex] = useState(0);
  const [editItem, setEditItem] = useState(null);
  const { height, width } = useWindowDimensions();

  const [room, setRoom] = useState("");
  const [phone, setPhone] = useState("");
  const [name, setName] = useState("");

  const [isLoading, setLoading] = useState(false);


  //Context
  const {
    pax,
    cart,
    token,
    userId,
    orderTime,
    company,
    tax,
    paymentData,
    isTakeAway,
    hasPayment,
    paymentPlatform,
    isDemo,
    lanIndex,
    isLanReverse,
    currencySymbol,
    allowTAafterWH,
    startTime,
    endTime,
    otherUrls,
    deliveryOrNot,
    deliveryCosts,
    minimumConsumption,
    PaymentTakeawayOnly,
    haveToPayOnline,
    isOnlinePayMinConsumption,
    onlinePayMinConsumptionVal,
    includeDineIn,
    tkDiMenuDiff,
    tkType,
    adCustomer,
    hasTakeAway,
    customer,
    skipDIPickUpTime,
    skipCustomerDetail,
    reserveData,
    hidePrice,
    pm,
    payAtCounter,
    hasVoucher,
    advocadoRedeem,
    vouchers,
    deliveryCalMethod,
    containerItems,
    isInRoom,
    takeAwayInfo,
    selectPUTimeFirst
  } = React.useContext(UserContext);

  const { clearCart, removeFromCart, addToCart, setReserveData, setFinalOrder, addPromotion, addPostal } = React.useContext(ActionContext);
  const themeContext = React.useContext(ThemeContext);
  const language = React.useContext(LanguageContext);

  const [subtotal, setSubtotal] = useState(promo.subTotalAmount(cart));
  const [defaulAmount, setDefaultAmount] = useState(promo.subTotalDefaultAmount(cart));
  const [serviceCharge, setServiceCharge] = useState((!isTakeAway && !paymentData.shouldExcludeDI) ? paymentData.serviceCharge : ((isTakeAway && !paymentData.shouldExclude) ? paymentData.serviceCharge : 0));
  const [finalAmount, setFinalAmount] = useState(promo.getFinalAmount(cart, "", serviceCharge, tax));

  useEffect(() => {
    let serviceCharge = (!isTakeAway && !paymentData.shouldExcludeDI) ? paymentData.serviceCharge : ((isTakeAway && !paymentData.shouldExclude) ? paymentData.serviceCharge : 0);
    setServiceCharge(serviceCharge);
    setFinalAmount(promo.getFinalAmount(cart, "", serviceCharge, tax));
    let checkinTime = localStorage.getItem("checkIn");
    if (checkinTime) {
      let diff = (new Date().getTime() - checkinTime) / 1000 / 60 / 60;

      if (diff > 1) {
        window.location.replace(otherUrls && otherUrls.length > 0 ? otherUrls[0].url : "https://google.com");
      }
    }

    if (isTakeAway && !allowTAafterWH) {
      let stime = moment(startTime);
      let etime = moment(endTime);
      let st = moment().set("hour", stime.get("hour")).set("minute", stime.get("minute"));
      let et = moment().set("hour", etime.get("hour")).set("minute", etime.get("minute"));
      if (stime.get("hour") < etime.get('hour')) {
        et = et.add(24, "hour");
      }
      if (moment().isBefore(st) || moment().isAfter(et)) {
        window.location.replace(otherUrls && otherUrls.length > 0 ? otherUrls[0].url : "https://google.com");
      }
    }
    if (isTakeAway) {
      setDeliveryType(tkType);
    } else if (tkType == 4) {
      setDeliveryType(4);
    }
  }, [isTakeAway, paymentData]);

  /********
   * EDIT ITEM FUNCTION
   * ********************* */
  //Remove from cart
  const handleDelete = (cart, index) => {
    cart.splice(index, 1);
    localStorage.setItem("order", JSON.stringify(cart));
    removeFromCart(cart, index);
    setDefaultAmount(promo.subTotalDefaultAmount(cart));
    setSubtotal(promo.subTotalAmount(cart));
    setFinalAmount(promo.getFinalAmount(cart, "", serviceCharge, tax));
  };

  //add from cart
  const handleAdd = (cart, index) => {
    cart[index].qty += 1;
    if (cart[index].qty == 0) {
      cart.splice(index, 1);
    }
    console.log(pm);
    if (pm && pm.length > 0) {
      cart = promo.clearPromotion(cart);
      cart = promo.applyPromotion(pm, cart);
    }
    if (isTakeAway) {
      cart = promo.removeContainer(cart);
      cart = promo.applyContainer(cart, containerItems);
    }

    localStorage.setItem("order", JSON.stringify(cart));
    removeFromCart(cart, index);

    setDefaultAmount(promo.subTotalDefaultAmount(cart));
    setSubtotal(promo.subTotalAmount(cart));
    setFinalAmount(promo.getFinalAmount(cart, "", serviceCharge, tax));
  }

  const handleItemClick = (item, index) => () => {
    setEditIndex(index);
    setEditItem(JSON.parse(JSON.stringify(item)));
    setShowEdit(true);
  };

  const handleMinus = (cart, index) => {
    cart[index].qty -= 1;
    if (cart[index].qty == 0) {
      cart.splice(index, 1);
    }
    if (pm && pm.length > 0) {
      cart = promo.clearPromotion(cart);
      cart = promo.applyPromotion(pm, cart);
    }
    if (isTakeAway) {
      cart = promo.removeContainer(cart);
      cart = promo.applyContainer(cart, containerItems);
    }
    localStorage.setItem("order", JSON.stringify(cart));

    removeFromCart(cart, index);
    setDefaultAmount(promo.subTotalDefaultAmount(cart));
    setSubtotal(promo.subTotalAmount(cart));
    setFinalAmount(promo.getFinalAmount(cart, "", serviceCharge, tax));
  }




  const notAllowToCheck = () => {
    setError([{
      errorElement: "No Item Selected",
      errorMessage: (
        <b style={{ color: "#CF000D" }}>{language[lanIndex].pleaseSelectItem}</b>
      )
    }])
    setShow(true);

  }
  const minimumConsumptionTip = () => {
    setError([{
      errorMessage: (
        <b style={{ color: "#CF000D" }}>{`${language[lanIndex].minimumConsumptionTip} ${minimumConsumption}`} </b>
      )
    }])
    setShow(true);

  }

  const sendOrder = async () => {
    //check if demo
    if (isDemo) return;
    //check if has item
    if (cart.length == 0) {
      notAllowToCheck();
      return;
    }

    if (isTakeAway || isInRoom) { //TakeAway
      if (hasPayment) { //check if has exceed min. spending amt
        if (isOnlinePayMinConsumption && subtotal < onlinePayMinConsumptionVal) {
          setError([{
            errorElement: language[lanIndex].belowMinSpendning,
            errorMessage: (
              <b style={{ color: "#CF000D" }}>{language[lanIndex].belowMinSpending2}<br />
                {language[lanIndex].belowMinSpending3 + " " + currencySymbol + onlinePayMinConsumptionVal.toFixed(2)}</b>
            )
          }])
          setShow(true);
          return;
        }
      }
      if (selectPUTimeFirst) {
        confirmTKCheckOut(takeAwayInfo.customerName, takeAwayInfo.phone,
          takeAwayInfo.scheduleTime, takeAwayInfo.address, takeAwayInfo.remark,
          takeAwayInfo.checkbox, takeAwayInfo.deliveryType, takeAwayInfo.roomNo);
        return;
      }


      if ((deliveryOrNot && hasTakeAway) || (includeDineIn && !tkDiMenuDiff)) { //商家是否开通外卖 
        return setShowDelive(true); // 询问TakeAway or delivery
      } else {
        if (!includeDineIn || !tkDiMenuDiff) {
          if (reserveData && reserveData != null) {
            setDeliveryType(4);
          } else if (deliveryOrNot) {
            setDeliveryType(2);
          } else if (hasTakeAway) {
            setDeliveryType(0);
          }
        }
        return setShowTk(true);  // 填写联系方式
      }
    } else { // dine in
      if (hasPayment && !PaymentTakeawayOnly) { // Payment Integration
        if (haveToPayOnline && !payAtCounter) {//必须 paynow
          confirmPTCheckOut(0);
        } else {
          setShowPayment(true); //询问paynow or paylater
        }
      } else { // 去付款
        setShowConfirm(true)
      };
    }
  }

  //confirm message
  const confirmToCheckout = async () => {
    setLoading(true);
    let order = {
      pax: pax,
      data: cart,
      orderId: "SO_" + new Date().getTime(),
      customerName: name,
      phone: phone,
      roomNo: room,
      userId: userId,
      adCustomer,
      customer,
      reserveId: reserveData ? reserveData.reservationId : "",
      deliveryType: deliveryType,
      vouchers: vouchers
    };
    await sendOrderToServer(order);
  }

  //online payment, Will transfer to new page
  const confirmPTCheckOut = async (payType, nm, ph, rm) => {
    if (payType == 0) {
      //double check if totalAmount is greater than minConsumption
      if (isOnlinePayMinConsumption && subtotal < onlinePayMinConsumptionVal) {
        setError([{
          errorElement: language[lanIndex].belowMinSpendning,
          errorMessage: (
            <b style={{ color: "#CF000D" }}>{language[lanIndex].belowMinSpending2}<br />
              {language[lanIndex].belowMinSpending3 + " " + currencySymbol + onlinePayMinConsumptionVal.toFixed(2)}</b>
          )
        }])
        setShow(true);
        return;
      }
      setLoading(true);
      let order = {
        pax: pax,
        data: cart,
        orderId: "SO_" + new Date().getTime(),
        userId: userId,
        adCustomer, customer,
        reserveId: reserveData ? reserveData.reservationId : "",
        deliveryType,
        vouchers,
        deliveryPrice: deliveryPrice,
        customerName: nm ? nm : name,
        phone: ph ? ph : phone,
        roomNo: rm ? rm : room,
      };
      await sendOrderToPayment(order);
    } else { //pay later
      setShowConfirm(true)
    }
  }

  //takeaway checkout after confirm delivery
  const confirmTKCheckOut = async (customerName, phone, scheduleTime, address, remark, checkbox, deliveryType, roomNo) => {
    if (isInRoom) {
      setRoom(roomNo);
      setName(customerName);
      setPhone(phone);
      if (payAtCounter) {
        setShowPayment(true);
      } else {
        confirmPTCheckOut(0, customerName, phone, roomNo);
      }
    } else if (hasPayment) {
      setLoading(true);
      let order = {
        pax: pax,
        data: cart,
        orderId: "SO_" + new Date().getTime(),
        customerName,
        phone, userId,
        scheduleTime,
        address, remark,
        checkbox, deliveryType,
        deliveryPrice,
        adCustomer,
        roomNo,
        customer, reserveId: reserveData ? reserveData.reservationId : "", vouchers
      };
      await sendOrderToPayment(order);
    } else {
      let order = {
        pax: pax, data: cart, orderId: "SO_" + new Date().getTime(), customerName, phone, userId,
        scheduleTime, address, remark, checkbox, deliveryType, deliveryPrice, adCustomer,
        customer, reserveId: reserveData ? reserveData.reservationId : "", vouchers,
        roomNo
      };
      await sendOrderToServer(order);
    };
  }

  const confirmDelivery = async () => {
    if (minimumConsumption != 0 && subtotal < minimumConsumption && deliveryType == 2) { // Minimum Consumption tip
      return minimumConsumptionTip();
    }
    //if istakeaway and user choose dine in and skip details 
    if (deliveryType == 1 && skipDIPickUpTime && skipCustomerDetail) {
      confirmTKCheckOut("", "", moment().valueOf(), "", "", [], deliveryType);
      return;
    }
    setShowTk(true);
  }

  //redirect to online payment
  const sendOrderToPayment = async (order) => {
    localStorage.setItem("orderId", order.orderId);
    localStorage.setItem("order", JSON.stringify(cart));
    localStorage.setItem("finalOrder", JSON.stringify(order));
    setFinalOrder(order);
    localStorage.setItem("shouldRedirect", "YES");
    navigate('/payment');

  }

  const sendOrderToServer = async (order) => {
    await axios.post("/v2/api/selfOrder/order", order, { headers: { Authorization: "Bearer " + token } })
      .then(async function (response) {
        await axios.get(`/v2/api/selfOrder/order?userId=${userId}&orderTime=${orderTime}&orderId=${order.orderId}`,
          { headers: { Authorization: "Bearer " + token } }
        );
      })
      .then(function (response) {
        // handle success
        localStorage.setItem("orderId", order.orderId);
        clearCart();
        setLoading(false);
        let newPromo = pm;
        newPromo = newPromo.filter((p) => !p.isVoucher);
        addPromotion(newPromo);
        //push to success page
        navigate('/payment/success');
      })
      .catch(function (response) {
        // handle error
        navigate('/order/fail');
        console.log(response);
      });


  }




  useEffect(() => {
    if (deliveryType == 0 || deliveryType == 1 || deliveryType == 4) {
      setDeliveryPrice(0);
      return;
    }
    //here to determine
    if (deliveryCalMethod == 0) {
      deliveryCosts.forEach((item) => {
        if (subtotal >= item.minPrice && item.maxPrice > subtotal) {
          setDeliveryPrice(item.deliveryPrice);
        }
      })
    }
  }, [subtotal, deliveryType]);

  useEffect(() => {
    console.log("HAS CART CHANGE");
    //reset all voucher promo
    for (let c of cart) {
      c.hasSpecialDiscount = false;
      c.specialDiscount = 0;
      c.specialDiscountAmount = 0;
      c.specialDiscountRemark = "";

    }
    setDefaultAmount(promo.subTotalDefaultAmount(cart));
    setSubtotal(promo.subTotalAmount(cart));
    setFinalAmount(promo.getFinalAmount(cart, "", serviceCharge, tax));
  }, [cart]);

  useEffect(() => {
    let order = JSON.parse(localStorage.getItem("order"));
    let postal = localStorage.getItem("postal");
    if (postal) {
      addPostal(postal);
    }
    if (order) {
      addToCart(order);
    }
  }, [addToCart]);

  useEffect(() => {
    let reserveData = JSON.parse(localStorage.getItem("reserveData"));

    if (reserveData && reserveData != null) {
      setReserveData(reserveData);
      console.log("SET DELIVERY TYPE");
      setDeliveryType(4);
    }
  }, [setReserveData]);


  if (typeof lanIndex === 'undefined') {
    return <></>
  }
  return (
    <BackgroundColor themeContext={themeContext}>
      {isLoading ? (
        <LoaderContainer>
          <Loader
            type="ThreeDots"
            color={themeContext.primaryColor}
            height="100"
            width="100"
          />
        </LoaderContainer>
      ) : (
        <React.Fragment>
          <CheckoutButton
            themeContext={themeContext}
            onClick={sendOrder}
            active={true}
          >
            {hasPayment && !PaymentTakeawayOnly ?
              language[lanIndex].submitAndPay : language[lanIndex].submit}
          </CheckoutButton>
          <HeaderContainer
            themeContext={themeContext}
            headerHeight={themeContext.headerHeight}
          >
          </HeaderContainer>
          <ArrowContainer themeContext={themeContext} headerHeight={themeContext.headerHeight}>
            <Link
              to="/menu"
              style={{ display: "grid", placeContent: "center" }}
            >
              <IoIosArrowBack
                color={themeContext.checkOutArrow}
                size={themeContext.headerHeight}
              />
            </Link>
          </ArrowContainer>

          <MenuTitleContainer
            headerHeight={themeContext.headerHeight}
            themeContext={themeContext}
          >{language[lanIndex].cart}</MenuTitleContainer>

          <Buffer height={themeContext.headerHeight + 30} />
          {!isTakeAway && deliveryType == 4 &&
            <ReserveInfo />
          }
          {!isTakeAway && deliveryType !== 4 &&
            <TableInfo />
          }

          <React.Fragment>
            {cart.map((item, index) => {
              return (
                <CheckoutPanel
                  key={index + item.title + item.codeId}
                  cart={cart}
                  handleDelete={handleDelete}
                  handleAdd={handleAdd}
                  handleMinus={handleMinus}
                  handleItemClick={handleItemClick}
                  index={index}
                  item={item}
                  height={height}
                  width={width}
                  lanIndex={lanIndex}
                  isLanReverse={isLanReverse}
                  currencySymbol={currencySymbol}
                  deleteActive={true}
                  hidePrice={hidePrice}
                />
              );
            })}
            <Buffer height={250} />
            {!hidePrice &&
              <SubTotalWrapper width={Math.min(550, width) - 20}>
                {hasVoucher && !advocadoRedeem &&
                  //voucher promo area
                  <SubtotalAndTaxContainer
                    themeContext={themeContext}
                    width={Math.min(550, width) - 40}
                  >
                    <VoucherField />
                  </SubtotalAndTaxContainer>
                }
                <SubtotalAndTaxContainer
                  themeContext={themeContext}
                  width={Math.min(550, width) - 40}
                >
                  <LeftColumn>
                    {language[lanIndex].subtotal}
                  </LeftColumn>
                  <RightColumn>
                    {currencySymbol}&nbsp;{defaulAmount.toFixed(2)}
                  </RightColumn>

                </SubtotalAndTaxContainer>
                {defaulAmount != subtotal &&
                  <SubtotalAndTaxContainer
                    themeContext={themeContext}
                    width={Math.min(550, width) - 40}
                  >
                    <LeftColumn>
                      {language[lanIndex].discount}
                    </LeftColumn>
                    <RightColumn>
                      {currencySymbol}&nbsp;{(subtotal - defaulAmount).toFixed(2)}
                    </RightColumn>

                  </SubtotalAndTaxContainer>
                }
                <SubtotalAndTaxContainer
                  themeContext={themeContext}
                  width={Math.min(550, width) - 40}
                >
                  <LeftColumn>
                    {language[lanIndex].tax} {isTakeAway ? "" : `& ${language[lanIndex].serviceCharge}`}
                  </LeftColumn>
                  <RightColumn>
                    {currencySymbol}&nbsp;{(finalAmount - subtotal).toFixed(2)}
                  </RightColumn>
                </SubtotalAndTaxContainer>

                {deliveryType == 2 &&
                  <SubtotalAndTaxContainer
                    themeContext={themeContext}
                    width={Math.min(550, width) - 40}
                  >
                    <LeftColumn>
                      {language[lanIndex].deliveryFee}
                    </LeftColumn>
                    <RightColumn>
                      {currencySymbol}&nbsp;{deliveryPrice.toFixed(2)}
                    </RightColumn>
                  </SubtotalAndTaxContainer>}

                <TotalContainer themeContext={themeContext} width={Math.min(550, width) - 40}>
                  <LeftColumn>
                    {language[lanIndex].total}
                  </LeftColumn>
                  <RightColumn>
                    {currencySymbol}&nbsp;{(deliveryPrice + finalAmount).toFixed(2)}
                  </RightColumn>
                </TotalContainer>
              </SubTotalWrapper>
            }
          </React.Fragment>

        </React.Fragment>
      )}

      <Modal show={show} setShow={setShow} error={error} />
      <ConfirmCheckOutModal show={showConfirm} setShow={setShowConfirm} confirmCheckOut={confirmToCheckout} lanIndex={lanIndex} />
      <TakeAwayModal show={showTk} setShowTk={setShowTk} deliveryType={deliveryType} confirmTKCheckOut={confirmTKCheckOut}
        setDeliveryPrice={setDeliveryPrice} deliveryPrice={deliveryPrice} lanIndex={lanIndex} subtotal={subtotal} currencySymbol={currencySymbol} />
      <DeliveryOrNotModal show={showDelive} setShowDelive={setShowDelive} type={deliveryType} setDeliveryType={setDeliveryType} deliveryOrNot={deliveryOrNot}
        confirmDelivery={confirmDelivery} lanIndex={lanIndex} deliveryPrice={deliveryPrice} currencySymbol={currencySymbol} includeDineIn={includeDineIn}
        tkDiMenuDiff={tkDiMenuDiff} hasTakeAway={hasTakeAway} />
      <PaymentTypeModal show={showPayment} setShow={setShowPayment} payType={payType} setPayType={setPayType} lanIndex={lanIndex}
        payAtCounter={payAtCounter} confirmPTCheckOut={confirmPTCheckOut} total={(deliveryPrice + subtotal).toFixed(2)} />
      {showEdit &&
        <EditItemModal show={showEdit} item={editItem} index={editIndex} setShow={setShowEdit} cart={cart} />
      }
    </BackgroundColor>
  );
};

export default Checkout;

//Styled Components
const BackgroundColor = styled.div`
  height: 100vh;
  width: 100vw;
  max-width: 550px;
  background-color: ${(props) => props.themeContext.backgroundColor};
  overflow-y: scroll;
  z-index: -10;
`;

const HeaderContainer = styled.div`
  display: flex;
  flex-direction: column;
  position: fixed;
  width: 100vw;
  max-width: 550px;
  top: 0rem;
  flex-direction: column;
  height: ${(props) => props.headerHeight + 10}px;
  background-color: ${(props) => props.themeContext.secondaryColor};
  z-index: 5;
  box-shadow: 0px 1px 3px rgba(0,0,0, 45%);
`;

const ArrowContainer = styled.div`
  position: fixed;
  place-content: center;
  z-index: 10;
  width: 60px;
  height: ${(props) => props.headerHeight + 10}px;
  display: flex;
`;

const CheckoutButton = styled.div`
  display: ${(props) => (props.active ? "grid" : "none")};
  position: fixed;
  bottom: 10px;
  
  text-align: center;
  color: ${(props) => props.themeContext.cartFont};
  font-size: 18px;
  font-family: ${(props) => props.themeContext.fontFamily};
  background-color: ${(props) => props.themeContext.cartColor1};
  justify-content: center;
  align-items: center;
  height: 40px;
  border-radius: 7px;
  box-shadow: 0px 0px 6px rgb(0 0 0 / 70%);
  z-index: 3;
  max-width:500px;
  width: 85%;
  margin-left: 25px;
  animation: moveBox2 1.8s infinite;
  @keyframes moveBox2 {
    
    0%  {
      box-shadow: 1px 1px 3px rgb(0 0 0 / 70%);
      transform: translateX(0px);
    }
    50%   {
      box-shadow: 1px 1px 13px rgb(0 0 0 / 100%);
      transform: translateX(5px);
    }
    100%  {
      box-shadow: 1px 1px 3px rgb(0 0 0 / 70%);
      transform: translateX(0px);
    }
    
  }

`;

const LoaderContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  place-content: center;
`;

const MenuTitleContainer = styled.div`
  position: fixed;
  font-size: 16px;
  font-weight: bold;
  color: ${(props) => props.themeContext.checkOutArrow};
  font-family: ${(props) => props.themeContext.fontFamily};
  margin-left: 48px;
  display: flex;
  align-items: center;
  height: ${(props) => props.headerHeight + 10}px;
  z-index: 5;
`;

const Buffer = styled.div`
  display: block;
  height: ${(props) => props.height}px;
`;

const SubTotalWrapper = styled.div`
  position: fixed;
  bottom: 0px;
  padding: 8px 20px;
  padding-bottom: 60px;
  background: white;
  border-top: 1px solid;
`;

const SubtotalAndTaxContainer = styled.div`
  display: flex;
  color: ${(props) => props.themeContext.fontPrimary};
  font-family: ${(props) => props.themeContext.fontFamily};
  width: ${(props) => props.width}px;
  font-weight: 500;
  font-size: 14px;
  padding-bottom: 4px;
`;

const LeftColumn = styled.div`
  flex-grow: 1;
`;

const RightColumn = styled.div`
  text-align: right;
`;

const TotalContainer = styled.div`
  display: flex;
  /*color: ${(props) => props.themeContext.fontPrice};*/
  font-family: ${(props) => props.themeContext.fontFamily};
  
  width: ${(props) => props.width}px;
  font-weight: 700;
  font-size: 18px;
`;

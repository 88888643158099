import React from "react";
import { CarouselProvider, Slider, Slide, DotGroup } from "pure-react-carousel";
import "pure-react-carousel/dist/react-carousel.es.css";
import MenuItem from "./MenuItem";
import ItemSize5 from "./ItemSize5";

const MenuCarousel = ({
  isSetMeal,
  array, //each Option Grou
  setArrayState,
  isMultiple,
  produceNewState,
  produceMultiState,
  hasRecommended,
  visibleSlide,
  adjustQuantity,
  lanIndex,
  isLanReverse,
  currencySymbol,
  menuIndex,
  cp,
  optionType
}) => {
  //when user click item
  const handleOnClick = (array, codeId, isMultiple) => {
    if (!hasRecommended && isMultiple) {
      setArrayState(produceMultiState(array, codeId));
    } else
      setArrayState(produceNewState(array, codeId, isMultiple, hasRecommended, menuIndex));
  };
  const handleQtyClick = (array, qty, index) => {
    setArrayState(adjustQuantity(array, qty, index));
  }
  return (
    <React.Fragment>
      {optionType == 5 && array.map((item, index) => (
        <div
          index={index}
          key={item.codeId ? item.codeId : "" + index + item.title}
        >
          <ItemSize5
            isSetMeal={isSetMeal}
            active={item.active}
            item={item}
            lanIndex={lanIndex}
            isLanReverse={isLanReverse}
            hasRecommended={hasRecommended}
            currencySymbol={currencySymbol}
            handleOnClick={() => {
              handleOnClick(array, item.codeId, isMultiple);
            }}
            adjustQuantity={(qty) => {
              handleQtyClick(array, qty, index);
            }}
            cp={0}
          />
        </div>
      ))}
      {optionType == 3 && array.map((item, index) => (
        <div
          index={index}
          key={item.codeId ? item.codeId : "" + index + item.title}
          style={{ height: hasRecommended ? 196 : (!cp ? 160 : 160), width: hasRecommended || cp == 1 ? '33.3%' : '50%', display: 'inline-block', overflow: 'hidden', textAlign: 'center' }}
        >
          <MenuItem
            isSetMeal={isSetMeal}
            active={item.active}
            containerHeight={!cp || hasRecommended ? 150 : 150}
            containerWidth={'92%'}
            item={item}
            lanIndex={lanIndex}
            isLanReverse={isLanReverse}
            hasRecommended={hasRecommended}
            currencySymbol={currencySymbol}
            handleOnClick={() => {
              handleOnClick(array, item.codeId, isMultiple);
            }}
            adjustQuantity={(qty) => {
              handleQtyClick(array, qty, index);
            }}
            cp={0}
          />
        </div>
      ))}
      {/* </Slider> */}
      {/* <DotGroup /> */}
    </React.Fragment>
  );
};

export default MenuCarousel;

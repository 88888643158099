import React, { useEffect, useRef } from "react";
import styled from "@emotion/styled";
import { UserContext, ThemeContext } from "../../contexts/Context";

const ItemSize5 = ({
  lanIndex,
  isLanReverse,
  item,
  handleOnClick,
  active,
  isSetMeal,
  selectedItems,
  currencySymbol,
  addItemDirect,
  cp,
  addQty,
  theme,
  minusQty,
  shouldHide,
}) => {
  //when user click item
  const themeContext = React.useContext(ThemeContext);
  const { displayNetAmount, tax } = React.useContext(UserContext);
  const ref = useRef(null);

  useEffect(() => {
    if (!shouldHide) return;
    if (!ref || ref == null) return;
    if (ref.current == null) return;
    if (ref.current.getBoundingClientRect().top < window.innerHeight) {
      ref.current.style.backgroundImage = `url('${ref.current.dataset.bg}')`;
      ref.current.classList.remove('item-img');
    }

  }, [ref]);
  return (
    <React.Fragment>
      <ItemWrapper themeContext={themeContext}
        active={active}
        onClick={(e) => {
          e.stopPropagation();
          if (!item.isSoldOut) {
            handleOnClick();
          }
        }}
      >
        <ItemDisplay

          ref={ref}
          key={item.image.codeId}
          className="item-img"
          data-bg={item.image}
          shouldHide={shouldHide}
          enlarge={cp == 1}
        >
        </ItemDisplay>
        <ItemTitleWrapper themeContext={themeContext} size={item.size} cp={cp}>
          <ItemTitle themeContext={themeContext} title={item.title} theme={theme} enlarge={cp == 1}>
            {(lanIndex == 0 && !isLanReverse) || (lanIndex == 1 && isLanReverse) ?
              (item.isCategory ? item.category : item.title) :
              (item.isCategory ? item.category2 : item.title2)
            }
          </ItemTitle>


          {item.price > 0 &&
            <PriceDisplay themeContext={themeContext} enlarge={cp == 1}>
              {`${currencySymbol} ${(displayNetAmount ? (item.price * (1 + (tax.taxMode == 0 ? tax.tax : item.tax))) : item.price).toFixed(2)}`}
            </PriceDisplay>
          }

          <ItemPlainDescription size={item.size} themeContext={themeContext}>
            {(lanIndex == 0 && !isLanReverse) || (lanIndex == 1 && isLanReverse) ?
              item.description :
              (item.description2 ? item.description2 : item.description)
            }
          </ItemPlainDescription>
          {Array.isArray(item.icons) && item.icons.length > 0 &&
            <IconHolder icons={item.icons} enlarge={cp == 1} />
          }
        </ItemTitleWrapper>

        {item.isSoldOut &&
          <SoldOut src="/soldout.png" />
        }

      </ItemWrapper>
    </React.Fragment>
  );
};

export default ItemSize5;

const ItemWrapper = styled.div`
    display: flex;
    flex-direction: row;
    border-radius: 6px;
    padding: 8px 4px;

    opacity: ${(props) => () => {
    if (props.hasRecommended || props.active === undefined) {
      return 1;
    } else {
      return props.active ? 1 : 0.5;
    }
  }};
    z-index:${(props) => props.hideDisplay ? 0 : 1};
    background-color:${(props) => props.size == 2 ? props.themeContext.optionHeaderBackgroundColor : ""};
    box-shadow: 0 0 7px rgba(0,0,0,0.35);
    margin: 8px 4px;
    position: relative;
`;

const ItemTitleWrapper = styled.div`
  flex: 6;
  padding: 0 8px;
  text-align: left;
`;
const ItemTitle = styled.div`
  font-family: ${(props) => props.themeContext.fontFamily};
  font-size: ${(props) => props.enlarge ? "16px" : "14px"};
  font-weight: 400;
  color: ${(props) => props.themeContext.fontPrimary};
  padding: 0 8px;
  -webkit-line-clamp: 2;
  word-wrap:break-word;
  -webkit-box-orient: vertical;
  overflow:hidden;
  text-align: left;
`;

const SoldOut = styled.img`
  width: 50%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;
const PriceDisplay = styled.div`
  color: ${(props) => props.themeContext.fontPrimary};
  font-weight: 500;
  font-size: ${(props) => props.enlarge ? "16px" : "14px"};
  margin-left: 8px;
`;

const ItemPlainWrapper = styled.div`

`;

const ItemPlainDescription = styled.div`
  display: -webkit-box;
  -webkit-line-clamp: 2;
  text-align: left;
  overflow: hidden;
  -webkit-box-orient: vertical;
  font-size: 12px;
  padding-left: 8px;
  padding-right: 8px;
  color: ${(props) => props.themeContext.fontPrimary};
  font-weight: 300;
  padding-top: 4px;
  margin-bottom: 8px;
`;

const ItemDisplay = styled.div`
  width: ${(props) => props.enlarge ? "100px" : "72px"};
  height: ${(props) => props.enlarge ? "100px" : "72px"};
 
  border-radius: 6px;
  //Image based on image url in item given
  background-image: url(${(props) => props.shouldHide ? "" : props["data-bg"]});
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  overflow: hidden;
`;

const ItemQtyTxt = styled.div`
  font-size: ${props => props.enlarge ? 20 : 16}px;
  width: ${props => props.enlarge ? 40 : 32}px;
  height: ${props => props.enlarge ? 40 : 32}px;
  color: white;
  display:flex;
  justify-content: center;
  align-items: center;
`;
const ItemQtyIcon = styled.div`
  width: ${props => props.enlarge ? 40 : 32}px;
  height: ${props => props.enlarge ? 40 : 32}px;
  border-radius: ${props => props.enlarge ? 20 : 16}px; 
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(255,255,255,0.6);
  color: black;
  font-size: 16px;
  font-weight:600;
`;
const ItemQtyWrapper = styled.div`
  display:flex;
  padding: ${(props) => props.size == 1 ? "16px  16px" : "8px 4px"};
  border-radius: 6px;
  background-color: rgba(0,0,0,0.6);
  justify-content: center;
  align-items: center;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
`;

const IconHolder = ({ icons, enlarge }) => {
  const userContext = React.useContext(UserContext);
  return (

    <IconsContainer enlarge={enlarge}>
      {Array.isArray(icons) && icons.map((iconsLink, index) => (
        <Icons
          enlarge={enlarge}
          key={index + iconsLink + "Icons"}
          iconsLink={iconsLink}
          iconsImg={userContext.icons}
        />
      ))}
    </IconsContainer>
  );
};

const Icons = styled.div`
  height: ${props => props.enlarge ? 30 : 24}px;
  width: ${props => props.enlarge ? 30 : 24}px;
  background-image: url(${(props) => props.iconsImg[props.iconsLink]});
  background-size: ${props => props.enlarge ? 30 : 24}px;
`;

const IconsContainer = styled.div`
  margin-left: 8px;
  display: flex;
  font-size: ${props => props.enlarge ? 24 : 20}px;
  min-width: ${props => props.enlarge ? 30 : 24}px;
  height: ${props => props.enlarge ? 30 : 24}px;
`;
/* eslint-disable no-console */
import React, { useState, useEffect, useRef } from "react";

import { Link, navigate } from "@reach/router";
import styled from "@emotion/styled";
// import produce from "immer";
import useWindowDimensions from "../hooks/useWindowDimensions";
import axios from "axios";
import moment from 'moment';
import Modal from "../newcomponents/optioncomponents/Modal";
import TakeAwayModal from '../newcomponents/optioncomponents/TakeAwayModal';
import { IoIosArrowBack } from "react-icons/io";
import promo from '../newcomponents/customerComponents/Promotion';
import { UserContext, ActionContext, ThemeContext, LanguageContext } from "../contexts/Context";
import AdvocadoLogin from '../newcomponents/advocadoComponents/AdvocadoLogin';
import TableInfo from '../newcomponents/CheckOutInfo/TableInfo';
import ReserveInfo from "../newcomponents/CheckOutInfo/ReserveInfo";
import Loader from "react-loader-spinner";
import PickupInfo from "../newcomponents/CheckOutInfo/PickupInfo";
import DeliveryInfo from "../newcomponents/CheckOutInfo/DeliveryInfo";
import { IoGift, IoTrashBinOutline } from "react-icons/io5";
import { TbDiscount2 } from 'react-icons/tb';
import AdvocadoRedeem from '../newcomponents/advocadoComponents/AdvocadoRedeem';
import VoucherField from '../newcomponents/customerComponents/VoucherField';
// import redeem from '../screens/redeem';
const Checkout = () => {
  //component ref
  const orderRef = useRef(null);
  const asiaPaySubmit = useRef(null);
  const [showAdvocadoLogin, setShowAdvocadoLogin] = useState(false);

  const [show, setShow] = useState(false); //warning dialog
  const [showDialog, setDialog] = useState(false); //display dialog to show Redeem Dialog
  const [showTk, setShowTk] = useState(false); //display dialog to input cusomter detail
  //0 is TakeAway  1 is Dine in
  const [deliveryType, setDeliveryType] = useState(0); //user select delivery or pickup or dinein
  const [deliveryPrice, setDeliveryPrice] = useState(0); //display delivery | pickup option
  const [error, setError] = useState([]);

  const { height, width } = useWindowDimensions();

  //State
  // const [arrayBarState, setArrayBarState] = useState([
  //   { itemOptionId: 0, title: "Cart", title2: "菜单", active: true, menuTitle: "CURRENT ORDER", menuTitle2: "当前订单" },
  //   { itemOptionId: 1, title: "Review Order", title2: "查看已下订单", active: false, menuTitle: "REVIEW ORDER HISTORY", menuTitle2: "查看已下订单" },
  // ]);
  const [isLoading, setLoading] = useState(false);
  //Context
  const {
    cart,
    token,
    userId,
    orderTime,
    company,
    tax,
    paymentData,
    isTakeAway,
    hasPayment,
    paymentPlatform,
    isDemo,
    lanIndex,
    currencySymbol,
    allowTAafterWH,
    startTime,
    endTime,
    otherUrls,
    hasAdvocado,
    advocadoRedeem,
    deliveryCosts,
    finalOrder,
    isOnlinePayMinConsumption,
    onlinePayMinConsumptionVal,
    discount,
    redeem,
    adCustomer,
    hasVoucher,
    pm,
    deliveryCalMethod
  } = React.useContext(UserContext);

  const { clearCart, addToCart, setReserveData, setFinalOrder, setRedeem, setDiscount, addVoucher, addPromotion } = React.useContext(ActionContext);
  const themeContext = React.useContext(ThemeContext);
  const language = React.useContext(LanguageContext);

  useEffect(() => {
    let finalOrder = JSON.parse(localStorage.getItem("finalOrder"));

    if (finalOrder && finalOrder != null && finalOrder.data.length > 0) {
      setFinalOrder(finalOrder);
      addVoucher(finalOrder.vouchers);
      addToCart(finalOrder.data);
      setDeliveryType(finalOrder.deliveryType);
      console.log({ Delivery: finalOrder.deliveryPrice });
      setDeliveryPrice(finalOrder.deliveryPrice ? finalOrder.deliveryPrice : 0);
      setRedeem(finalOrder.redeem);
      setDiscount(finalOrder.discount);
      setSubtotal(promo.subTotalAmount(finalOrder.data, finalOrder.discount));
      setDefaultAmount(promo.subTotalDefaultAmount(finalOrder.data))
      setFinalAmount(promo.getFinalAmount(finalOrder.data, finalOrder.discount, serviceCharge, tax));
    }
  }, [setFinalOrder]);

  useEffect(() => {
    let shouldRedirect = localStorage.getItem("shouldRedirect");

    //if dont have advocado redemption, skip this screen
    if (!advocadoRedeem && cart.length != 0 && paymentPlatform != 0 && shouldRedirect == "YES" && finalOrder != null && finalOrder.data.length > 0) {
      localStorage.setItem("shouldRedirect", "NO");
      sendOrder();
    }
  }, [cart, paymentPlatform, advocadoRedeem, finalOrder]);

  useEffect(() => {
    let serviceCharge = (!isTakeAway && !paymentData.shouldExcludeDI) ? paymentData.serviceCharge : ((isTakeAway && !paymentData.shouldExclude) ? paymentData.serviceCharge : 0);
    setServiceCharge(serviceCharge);
    setFinalAmount(promo.getFinalAmount(cart, discount, serviceCharge, tax));
    let checkinTime = localStorage.getItem("checkIn");
    if (checkinTime) {
      let diff = (new Date().getTime() - checkinTime) / 1000 / 60 / 60;

      if (diff > 1) {
        window.location.replace(otherUrls && otherUrls.length > 0 ? otherUrls[0].url : "https://google.com");
      }
    }

    if (isTakeAway && !allowTAafterWH) {
      let stime = moment(startTime);
      let etime = moment(endTime);
      let st = moment().set("hour", stime.get("hour")).set("minute", stime.get("minute"));
      let et = moment().set("hour", etime.get("hour")).set("minute", etime.get("minute"));
      if (stime.get("hour") < etime.get('hour')) {
        et = et.add(24, "hour");
      }
      if (moment().isBefore(st) || moment().isAfter(et)) {
        window.location.replace(otherUrls && otherUrls.length > 0 ? otherUrls[0].url : "https://google.com");
      }
    }
  }, [isTakeAway, paymentData]);

  const [subtotal, setSubtotal] = useState(promo.subTotalAmount(cart));
  const [defaulAmount, setDefaultAmount] = useState(promo.subTotalDefaultAmount(cart));
  const [serviceCharge, setServiceCharge] = useState((!isTakeAway && !paymentData.shouldExcludeDI) ? paymentData.serviceCharge : ((isTakeAway && !paymentData.shouldExclude) ? paymentData.serviceCharge : 0));
  const [finalAmount, setFinalAmount] = useState(promo.getFinalAmount(cart, "", serviceCharge, tax));


  const notAllowToCheck = () => {
    setError([{
      errorElement: "No Item Selected",
      errorMessage: (
        <b style={{ color: "#CF000D" }}>{language[lanIndex].pleaseSelectItem}</b>
      )
    }])
    setShow(true);
  }

  const sendOrder = async () => {
    //check if demo
    if (isDemo) return;
    //check if has item
    if (cart.length == 0) {
      notAllowToCheck();
      return;
    }

    if (isTakeAway) { //TakeAway
      if (hasPayment) { //check if has exceed min. spending amt
        if (isOnlinePayMinConsumption && subtotal < onlinePayMinConsumptionVal) {
          setError([{
            errorElement: language[lanIndex].belowMinSpendning,
            errorMessage: (
              <b style={{ color: "#CF000D" }}>{language[lanIndex].belowMinSpending2}<br />
                {language[lanIndex].belowMinSpending3 + " " + currencySymbol + onlinePayMinConsumptionVal.toFixed(2)}</b>
            )
          }])
          setShow(true);
          return;
        }
      }
    }
    sendOrderToPayment(finalOrder);
  }

  //takeaway checkout after confirm delivery
  const confirmTKCheckOut = async (customerName, phone, scheduleTime, address, remark, checkbox, deliveryType, roomNo) => {
    finalOrder.customerName = customerName;
    finalOrder.phone = phone;
    finalOrder.scheduleTime = scheduleTime;
    finalOrder.address = address;
    finalOrder.remark = remark;
    finalOrder.roomNo = roomNo;
    finalOrder.checkbox = checkbox;
    finalOrder.deliveryType = deliveryType;
  }

  //redirect to online payment
  const sendOrderToPayment = async (order) => {

    setLoading(true);

    //store pending order
    await axios.post('/v2/api/selfOrder/onlinePayment', order, { headers: { Authorization: "Bearer " + token } });
    orderRef.current.value = order.orderId;
    localStorage.setItem("orderId", order.orderId);
    localStorage.setItem("order", JSON.stringify(cart));
    let amount = (finalAmount + deliveryPrice).toFixed(2);
   
    if (paymentPlatform == 0) { //asiapay
      asiaPaySubmit.current.click();
    } else if (paymentPlatform == 1) { //hitpay
      let response = await axios.post('/v2/api/hitpay', { amount: amount, orderId: order.orderId }, { headers: { Authorization: "Bearer " + token } });
      if (response.status == 200) {
        setTimeout(function () { window.location.assign(response.data.url); }, 250);
        clearCart();
        setFinalOrder({});
        setRedeem([]);
        addVoucher([]);
        setDiscount(null);
        let newPromo = pm;
        newPromo = newPromo.filter((p) => !p.isVoucher);
        addPromotion(newPromo);
      }
    } else if (paymentPlatform == 2) {
      let response = await axios.post('/v2/api/pocketPay', { amount: amount, orderId: order.orderId }, { headers: { Authorization: "Bearer " + token } });
      if (response.status == 200) {
        setTimeout(function () { window.location.assign(response.data.url); }, 250);
        clearCart();
        setFinalOrder({});
        setRedeem([]);
        addVoucher([]);
        setDiscount(null);
        let newPromo = pm;
        newPromo = newPromo.filter((p) => !p.isVoucher);
        addPromotion(newPromo);
      }
    } else if (paymentPlatform == 3) {//qulb payment
      let response = await axios.post('/v2/api/qlubPay', { amount: amount, orderId: order.orderId }, { headers: { Authorization: "Bearer " + token } });
      if (response.status == 200) {
        let toF = setTimeout(function () {
          console.log(response.data.url);
          window.location.replace(response.data.url);
          //retry second time
          setTimeout(
            function () {
              console.log("RERUN");
              window.location.replace(response.data.url);
              clearTimeout(this);

            }
            , 3000);

          clearTimeout(this);
        }, 250);

        clearCart();
        setFinalOrder({});
        setRedeem([]);
        addVoucher([]);
        setDiscount(null);
        let newPromo = pm;
        newPromo = newPromo.filter((p) => !p.isVoucher);
        addPromotion(newPromo);

      }
    } else if (paymentPlatform == 4) { //smooch pay
      let response = await axios.post('/v2/api/smoochPay', { amount: amount, orderId: order.orderId }, { headers: { Authorization: "Bearer " + token } });
      if (response.status == 200) {
        let toF = setTimeout(function () {
          console.log(response.data.url);
          window.location.replace(response.data.url);
          //retry second time
          setTimeout(
            function () {
              console.log("RERUN");
              window.location.replace(response.data.url);
              clearTimeout(this);

            }
            , 3000);

          clearTimeout(this);
        }, 250);

        clearCart();
        setFinalOrder({});
        setRedeem([]);
        addVoucher([]);
        setDiscount(null);
        let newPromo = pm;
        newPromo = newPromo.filter((p) => !p.isVoucher);
        addPromotion(newPromo);

      }

    } else { //cant find any, byright shouldnt pass here
      clearCart();
      setFinalOrder({});
      setRedeem([]);
      addVoucher([]);
      setDiscount(null);
      let newPromo = pm;
      newPromo = newPromo.filter((p) => !p.isVoucher);
      addPromotion(newPromo);
    }
  }

  const handleRewardRemove = (r, i) => () => {
    if (redeem[i].campaignId == r.campaignId) {
      redeem.splice(i, 1);
      if (r.index == -1) {
        if (discount && discount.furtherDiscount > 0) {
          discount.furtherDiscount = 0;
          discount.hasFurtherDiscount = false;
          discount.furtherDiscountRemark = "";
        } else if (discount) {
          discount.furtherDiscountAmount -= r.spendingAmount;
          discount.hasFurtherDiscount = Math.round(discount.furtherDiscountAmount * 100) > 0;
          if (!discount.hasFurtherDiscount) {
            discount.furtherDiscountRemark = "";
          }
        }
        setDiscount(discount);
        setSubtotal(promo.subTotalAmount(cart, discount));
        setFinalAmount(promo.getFinalAmount(cart, discount, serviceCharge, tax));
      } else {
        if (cart[r.index].specialDiscount > 0) {
          cart[r.index].specialDiscount = 0;
          cart[r.index].hasSpecialDiscount = false;
          cart[r.index].specialDiscountRemark = "";
          cart[r.index].specialDiscountAmount = 0;
        } else {
          cart[r.index].specialDiscountAmount -= r.spendingAmount;
          cart[r.index].specialDiscount = 0;
          cart[r.index].hasSpecialDiscount = false;
          cart[r.index].hasSpecialDiscount = Math.round(cart[r.index].specialDiscountAmount * 100) > 0;
          if (!cart[r.index].hasSpecialDiscount) {
            cart[r.index].specialDiscountRemark = "";
          }
        }
      }

      finalOrder.redeem = redeem;
      finalOrder.data = cart;
      localStorage.setItem("finalOrder", JSON.stringify(finalOrder));
      setRedeem(redeem);
      addToCart(cart);
      setFinalOrder(finalOrder);
      setSubtotal(promo.subTotalAmount(cart, discount));
      setFinalAmount(promo.getFinalAmount(cart, discount, serviceCharge, tax));
    }
  }


  useEffect(() => {

    if (!deliveryType || deliveryType == 0 || deliveryType == 1 || deliveryType == 4) {
      // setDeliveryPrice(0);
      return;
    }
    if (deliveryCalMethod == 0) {
      deliveryCosts.forEach((item) => {
        if (subtotal >= item.minPrice && item.maxPrice > subtotal) {
          setDeliveryPrice(item.deliveryPrice);
        }
      })
    }
  }, [subtotal, deliveryType]);

  useEffect(() => {
    console.log("HAS CART CHANGE");
    setDefaultAmount(promo.subTotalDefaultAmount(cart));
    setSubtotal(promo.subTotalAmount(cart, discount));
    setFinalAmount(promo.getFinalAmount(cart, discount, serviceCharge, tax));
  }, [cart, discount]);

  useEffect(() => {
    let reserveData = JSON.parse(localStorage.getItem("reserveData"));
    if (reserveData) {
      setReserveData(reserveData);
      setDeliveryType(4);
    }
  }, [setReserveData]);


  let serviceAmt = ((!isTakeAway && !paymentData.shouldExcludeDI) ? paymentData.serviceCharge : ((isTakeAway && !paymentData.shouldExclude) ? paymentData.serviceCharge : 0));
  if (typeof lanIndex === 'undefined') {
    return <></>
  }

  return (
    <BackgroundColor themeContext={themeContext}>
      {isLoading ? (
        <LoaderContainer>
          <Loader
            type="ThreeDots"
            color={themeContext.primaryColor}
            height="100"
            width="100"
          />
        </LoaderContainer>
      ) : (
        <React.Fragment>
          <CheckoutButton
            themeContext={themeContext}
            onClick={sendOrder}
            active={true}
          >
            {language[lanIndex].submit}
          </CheckoutButton>
          <HeaderContainer
            themeContext={themeContext}
            headerHeight={themeContext.headerHeight}
          >
          </HeaderContainer>
          <ArrowContainer themeContext={themeContext} headerHeight={themeContext.headerHeight}>
            <Link
              to="/checkout"
              style={{ display: "grid", placeContent: "center" }}
            >
              <IoIosArrowBack
                color={themeContext.checkOutArrow}
                size={themeContext.headerHeight}
              />
            </Link>
          </ArrowContainer>

          <MenuTitleContainer
            headerHeight={themeContext.headerHeight}
            themeContext={themeContext}
          >{language[lanIndex].confirmPayment}</MenuTitleContainer>

          <Buffer height={themeContext.headerHeight + 30} />
          {!isTakeAway && deliveryType == 4 &&
            <ReserveInfo />
          }
          {!isTakeAway && deliveryType !== 4 &&
            <TableInfo />
          }
          {isTakeAway && deliveryType == 0 &&
            <PickupInfo setShow={setShowTk} />
          }
          {isTakeAway && deliveryType == 2 &&
            <DeliveryInfo setShow={setShowTk} />
          }

          {hasAdvocado && advocadoRedeem &&
            <RedeemButton themeContext={themeContext} onClick={() => {
              if (adCustomer && adCustomer != null) {
                setDialog(true);
              } else {
                setShowAdvocadoLogin(true);
              }
            }}>
              <IoGift fontSize={24} />
              <RedeemTxt>{language[lanIndex].redeemVoucher}</RedeemTxt>
            </RedeemButton>
          }
          <RedeemWrapper>
            {redeem && redeem.map((r, i) =>
              <RedeemContent key={i}>
                <RedeemIcon>
                  <TbDiscount2 fontSize={24} />
                </RedeemIcon>
                <RedeemTitle>
                  {r.name}
                </RedeemTitle>
                <RedeemRemove onClick={handleRewardRemove(r, i)}>
                  <IoTrashBinOutline fontSize={20} color={themeContext.fontAlert} />
                </RedeemRemove>
              </RedeemContent>
            )}
          </RedeemWrapper>
          <React.Fragment>
            {/* {cart.map((item, index) => {
              return (
                <CheckoutPanel
                  key={index + item.title + item.codeId}
                  cart={cart}
                  handleDelete={handleDelete}
                  handleAdd={handleAdd}
                  handleMinus={handleMinus}
                  handleItemClick={handleItemClick}
                  index={index}
                  item={item}
                  height={height}
                  width={width}
                  lanIndex={lanIndex}
                  currencySymbol={currencySymbol}
                  deleteActive={true}
                />
              );
            })} */}

            <Divider themeContext={themeContext} />
            <SubTotalWrapper>
              {hasVoucher && advocadoRedeem &&
                //voucher promo area
                <SubtotalAndTaxContainer
                  themeContext={themeContext}
                  width={Math.min(550, width) - 40}
                >
                  <VoucherField />
                </SubtotalAndTaxContainer>
              }

              <SubtotalAndTaxContainer
                themeContext={themeContext}
                width={Math.min(550, width) - 40}
              >
                <LeftColumn>
                  {language[lanIndex].subtotal}
                </LeftColumn>
                <RightColumn>
                  {currencySymbol}&nbsp;{defaulAmount.toFixed(2)}
                </RightColumn>

              </SubtotalAndTaxContainer>
              {defaulAmount != subtotal &&
                <SubtotalAndTaxContainer
                  themeContext={themeContext}
                  width={Math.min(550, width) - 40}
                >

                  <LeftColumn>
                    {language[lanIndex].discount}
                  </LeftColumn>
                  <RightColumn>
                    {currencySymbol}&nbsp;{(subtotal - defaulAmount).toFixed(2)}
                  </RightColumn>

                </SubtotalAndTaxContainer>
              }
              <SubtotalAndTaxContainer
                themeContext={themeContext}
                width={Math.min(550, width) - 40}
              >
                <LeftColumn>
                  {language[lanIndex].tax} {isTakeAway ? "" : `& ${language[lanIndex].serviceCharge}`}
                </LeftColumn>
                <RightColumn>
                  {currencySymbol}&nbsp;{(finalAmount - subtotal).toFixed(2)}
                </RightColumn>
              </SubtotalAndTaxContainer>

              {deliveryType == 2 &&
                <SubtotalAndTaxContainer
                  themeContext={themeContext}
                  width={Math.min(550, width) - 40}
                >
                  <LeftColumn>
                    {language[lanIndex].deliveryFee}
                  </LeftColumn>
                  <RightColumn>
                    {currencySymbol}&nbsp;{deliveryPrice.toFixed(2)}
                  </RightColumn>
                </SubtotalAndTaxContainer>}

              <TotalContainer themeContext={themeContext} width={Math.min(550, width) - 40}>
                <LeftColumn>
                  {language[lanIndex].total}
                </LeftColumn>
                <RightColumn>
                  {currencySymbol}&nbsp;{((deliveryPrice + finalAmount)).toFixed(2)}
                </RightColumn>
              </TotalContainer>
            </SubTotalWrapper>
          </React.Fragment>
          <Buffer height={180} />
        </React.Fragment>
      )}
      <form name="payFormCcard" method="post" action="
        https://test.paydollar.com/b2cDemo/eng/payment/payForm.jsp">
        <input type="hidden" name="merchantId" value="12108712" />
        <input type="hidden" name="amount" value={(deliveryPrice + finalAmount).toFixed(2)} />
        <input type="hidden" name="orderRef" value="123" ref={orderRef} />
        <input type="hidden" name="currCode" value="702" />
        <input type="hidden" name="mpsMode" value="NIL" />
        <input type="hidden" name="successUrl"
          value="https://selforder.ireachfnb.com/payment/success" />
        <input type="hidden" name="failUrl" value="https://selforder.ireachfnb.com/payment/fail" />
        <input type="hidden" name="cancelUrl"
          value="https://selforder.ireachfnb.com/payment/cancel" />
        <input type="hidden" name="payType" value="N" />
        <input type="hidden" name="lang" value="E" />
        <input type="hidden" name="payMethod" value="ALL" />
        <input type="hidden" name="secureHash" value="" />
        <input type="hidden" name="remark" value={company.shortName} />
        <input type="submit" name="submit" style={{ display: "none" }} ref={asiaPaySubmit} />
      </form>
      <Modal show={show} setShow={setShow} error={error} />
      {showTk &&
        <TakeAwayModal
          show={showTk}
          setShowTk={setShowTk}
          deliveryType={deliveryType}
          confirmTKCheckOut={confirmTKCheckOut}
          lanIndex={lanIndex}
          ph={finalOrder.phone}
          nm={finalOrder.customerName}
          dt={finalOrder.scheduleTime ? moment(finalOrder.scheduleTime).format("YYYY-MM-DD HH:mm") + " - " + moment(finalOrder.scheduleTime).add(30, "minute").format("HH:mm") : ""}
          addr={finalOrder.address}
          rm={finalOrder.roomNo}
        />
      }
      {showDialog &&
        <AdvocadoRedeem show={showDialog} setShow={setDialog} />
      }
      {showAdvocadoLogin &&
        <AdvocadoLogin
          show={showAdvocadoLogin}
          setShow={setShowAdvocadoLogin}
          handleCallback={() => { setShowAdvocadoLogin(false); setDialog(true); }}
        />
      }
    </BackgroundColor>
  );
};

export default Checkout;
const RedeemWrapper = styled.div`

`;
const RedeemIcon = styled.div`
  display: flex;
`;
const RedeemRemove = styled.div`
  padding: 8px;
`;
const RedeemTitle = styled.div`
  margin-left: 4px;
  flex-grow: 1;
  width: 100%;
`;
const RedeemContent = styled.div`
  display: flex;
  align-items: center;
  margin: 0 16px;
  margin-top: 4px;
  padding: 8px 0px;
  border-top: 1px solid #e3e3e3;
`;
const RedeemTxt = styled.div`
  margin-left: 4px;
`;
const RedeemButton = styled.div`
  margin: 0px 16px;
  margin-top: 16px;
  border-radius: 6px;
  box-shadow: 2px 2px 7px rgba(0,0,0,0.5);
  padding: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #FFF8E1;
`;
const Divider = styled.div`
border-bottom: 1px solid ${(props) => props.themeContext.borderColor};
height: 1px;
margin: 16px 16px;
`;
//Styled Components
const BackgroundColor = styled.div`
  height: 100vh;
  width: 100vw;
  max-width: 550px;
  background-color: ${(props) => props.themeContext.backgroundColor};
  overflow-y: scroll;
  z-index: -10;
`;

const HeaderContainer = styled.div`
  display: flex;
  flex-direction: column;
  position: fixed;
  width: 100vw;
  max-width: 550px;
  top: 0rem;
  flex-direction: column;
  height: ${(props) => props.headerHeight + 10}px;
  background-color: ${(props) => props.themeContext.secondaryColor};
  z-index: 5;
  box-shadow: 0px 1px 3px rgba(0,0,0, 45%);
`;

const ArrowContainer = styled.div`
  position: fixed;
  place-content: center;
  z-index: 10;
  width: 60px;
  height: ${(props) => props.headerHeight + 10}px;
  display: flex;
`;

const CheckoutButton = styled.div`
  display: ${(props) => (props.active ? "grid" : "none")};
  position: fixed;
  bottom: 10px;
  max-width:500px;
  text-align: center;
  color: ${(props) => props.themeContext.cartFont};
  font-size: 18px;
  font-family: ${(props) => props.themeContext.fontFamily};
  background-color: ${(props) => props.themeContext.cartColor1};
  justify-content: center;
  align-items: center;
  height: 40px;
  border-radius: 7px;
  box-shadow: 0px 0px 6px rgb(0 0 0 / 70%);
  z-index: 3;
  width: 85%;
  margin-left: 25px;
  animation: moveBox 1.8s infinite;
  @keyframes moveBox {
    
    0%  {
      box-shadow: 1px 1px 3px rgb(0 0 0 / 70%);
      transform: translateX(0px);
    }
    50%   {
      box-shadow: 1px 1px 13px rgb(0 0 0 / 100%);
      transform: translateX(5px);
    }
    100%  {
      box-shadow: 1px 1px 3px rgb(0 0 0 / 70%);
      transform: translateX(0px);
    }
    
  }

`;

const LoaderContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  place-content: center;
`;

const MenuTitleContainer = styled.div`
  position: fixed;
  font-size: 16px;
  font-weight: bold;
  color: ${(props) => props.themeContext.checkOutArrow};
  font-family: ${(props) => props.themeContext.fontFamily};

  margin-left: 48px;

  display: flex;
  align-items: center;
  height: ${(props) => props.headerHeight + 10}px;
  z-index: 5;
`;

const Buffer = styled.div`
  display: block;
  height: ${(props) => props.height}px;
`;

const SubTotalWrapper = styled.div`
  display: grid;
  place-content: center;
`;

const SubtotalAndTaxContainer = styled.div`
  display: flex;
  color: ${(props) => props.themeContext.fontPrimary};
  font-family: ${(props) => props.themeContext.fontFamily};
  width: ${(props) => props.width}px;
  font-weight: 500;
  font-size: 14px;
  padding-bottom: 4px;
`;

const LeftColumn = styled.div`
  flex-grow: 1;
`;

const RightColumn = styled.div`
  text-align: right;
`;

const TotalContainer = styled.div`
  display: flex;
  /*color: ${(props) => props.themeContext.fontPrice};*/
  font-family: ${(props) => props.themeContext.fontFamily};
  
  width: ${(props) => props.width}px;
  font-weight: 700;
  font-size: 18px;
`;

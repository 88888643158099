import React, { useState, useRef, useEffect } from "react";
import "../styles/styles.css";
import styled from "@emotion/styled";
import MenuOptions from "./optioncomponents/MenuOptions";
import RemarksTab from "./optioncomponents/RemarksTab";
import RecommendedEngine from './RecommendedEngine';
import QuantityAdjust from "./optioncomponents/QuantityAdjust";
import { ThemeContext, UserContext } from "../contexts/Context";
import { IoCloseOutline } from "react-icons/io5";
const MenuInfoContainer = ({ width, menuSelectionFormRef, selectedMenu, theme, isEdit, setShow, cp, addRecItemToCart }) => {
  const setPrice = useRef(null);
  const setComparePrice = useRef(null);
  const themeContext = React.useContext(ThemeContext);
  const { lanIndex, currencySymbol, isMenuView, tax, hidePrice, isLanReverse } = React.useContext(UserContext);

  const Buffer = styled.div`
    display: block;
    height: ${(props) => props.height}px;
  `;

  // if (selectedMenu) {
  //   console.log("MENU RESET AT MENU INFO");
  //   console.log(selectedMenu);
  //   menuSelectionFormRef.current = JSON.parse(JSON.stringify(selectedMenu));
  // }

  useEffect(() => {
    if (document.getElementById("menu_scroll") != null) {
      document.getElementById("menu_scroll").scrollTo({
        top: 0,
        left: 0,
        behavior: 'instant'
      });
    }
  }, [])
  //Method
  const adjustQuantity = (value) => {
    menuSelectionFormRef.current.qty = value;
  };
  const adjustRemark = (value) => {
    menuSelectionFormRef.current.remark = value;
  };
  const setPriceMethod = (value) => {
    if (!hidePrice)
      setPrice.current(value);
  };
  const setComparePriceMethod = (value) => {
    if (!hidePrice)
      setComparePrice.current(value);
  };

  return (
    <React.Fragment>
      <ItemDisplay
        title={(lanIndex == 0 && !isLanReverse) || (lanIndex == 1 && isLanReverse) ? selectedMenu.title : selectedMenu.title2 ? selectedMenu.title2 : selectedMenu.title}
        image={selectedMenu.image}
        themeContext={themeContext}
        theme={theme}
        isEdit={isEdit}
        height={(width) / 4 * 3}
      ></ItemDisplay>
      {isEdit &&
        <IconButton onClick={() => setShow(false)}>
          <IoCloseOutline size={36} />
        </IconButton>
      }
      <OptionsDisplay width={width} isEdit={isEdit} themeContext={themeContext} theme={theme}>
        <MenuInfoHeaderContainer>
          <TitleContainer
            themeContext={themeContext}
            itemTitle={(lanIndex == 0 && !isLanReverse) || (lanIndex == 1 && isLanReverse) ? selectedMenu.title : selectedMenu.title2 ? selectedMenu.title2 : selectedMenu.title}
          >{`${(lanIndex == 0 && !isLanReverse) || (lanIndex == 1 && isLanReverse) ? selectedMenu.title : selectedMenu.title2 ? selectedMenu.title2 : selectedMenu.title}`}</TitleContainer>
          {!hidePrice &&
            <PriceField setPrice={setPrice} setComparePrice={setComparePrice} selectedMenu={selectedMenu} currencySymbol={currencySymbol} />
          }
        </MenuInfoHeaderContainer>
        <DescriptionContainer themeContext={themeContext}>
          {(lanIndex == 0 && !isLanReverse) || (lanIndex == 1 && isLanReverse) ? selectedMenu.description : selectedMenu.description2 ? selectedMenu.description2 : selectedMenu.description}
        </DescriptionContainer>
        {selectedMenu.optionArray.map((item, index) => (
          item.options && item.options.length > 0 ?
            <MenuOptions
              key={item.optionTitle + index}
              selectedMenu={selectedMenu}
              menuIndex={index}
              menuSelectionFormRef={menuSelectionFormRef}
              menuOptions={item}
              setPrice={setPriceMethod}
              setComparePrice={setComparePriceMethod}
              isSetMeal={selectedMenu.isSetMeal}
              fixedPrice={selectedMenu.fixedPrice}
              cp={cp}
            />
            :
            <></>
        ))}

        {/* Quantity Component */}
        {!isMenuView && !selectedMenu.hideQtySelection ?
          <QuantityAdjust
            size={36}
            lanIndex={lanIndex}
            quantity={menuSelectionFormRef.current.qty ? menuSelectionFormRef.current.qty : (menuSelectionFormRef.current.minQty ? menuSelectionFormRef.current.minQty : 1)}
            minQty={menuSelectionFormRef.current.minQty ? menuSelectionFormRef.current.minQty : 1}
            adjustQuantity={adjustQuantity}
          />
          :
          adjustQuantity(1)
        }
        {/* Remarks Component */}
        {!isMenuView && menuSelectionFormRef.current.hasRemark ? (
          <RemarksTab
            lanIndex={lanIndex}
            state={menuSelectionFormRef.current.remark}
            setState={adjustRemark}
          />
        ) : (
          <React.Fragment />
        )}
        {(!isEdit && menuSelectionFormRef.current.recommendedEngine && menuSelectionFormRef.current.recommendedEngine.length > 0) &&
          <RecommendedEngine
            lanIndex={lanIndex}
            codeId={menuSelectionFormRef.current.codeId}
            recommendedEngine={JSON.parse(JSON.stringify(menuSelectionFormRef.current.recommendedEngine))}
            addRecItemToCart={addRecItemToCart}
          />

        }
        <Buffer height={80} />
      </OptionsDisplay>
      <div style={{ paddingBottom: 60 }}></div>
    </React.Fragment>
  );
};
export default MenuInfoContainer;

const PriceField = (props) => {
  const { tax, displayNetAmount, customer } = React.useContext(UserContext);

  const [priceHolder, setPriceHolder] = useState(
    (props.selectedMenu.price * (displayNetAmount ? (1 + (tax.taxMode == 0 ? tax.tax : props.selectedMenu.tax)) : 1)).toFixed(2)
  );
  const [priceCompareHolder, setPriceCompareHolder] = useState("");
  const [pbTitle, setPbTitle] = useState("");
  props.setPrice.current = setPriceHolder;
  props.setComparePrice.current = setPriceCompareHolder;
  const themeContext = React.useContext(ThemeContext);

  useEffect(() => {
    let comparePrice = "";
    if (props.selectedMenu.comparePrice) {
      comparePrice = (props.selectedMenu.comparePrice * (displayNetAmount ? (1 + (tax.taxMode == 0 ? tax.tax : props.selectedMenu.tax)) : 1)).toFixed(2);
    } else if (props.selectedMenu.pricebook && Object.keys(props.selectedMenu.pricebook).length > 0) {
      let bp = props.selectedMenu.pricebook[Object.keys(props.selectedMenu.pricebook)[0]];
      if (bp.minQty == 1) {
        comparePrice = (bp.price * (displayNetAmount ? (1 + (tax.taxMode == 0 ? tax.tax : props.selectedMenu.tax)) : 1)).toFixed(2);
        setPbTitle(bp.title);
      }
    }
    setPriceCompareHolder(comparePrice);

  }, []);

  return (
    <PriceWapper themeContext={themeContext}>

      {(typeof props.selectedMenu.comparePrice != 'undefined' && props.selectedMenu.comparePrice > 0) &&
        <PriceCompareContainer themeContext={themeContext}>
          {`${props.currencySymbol} ${priceCompareHolder}`}
        </PriceCompareContainer>
      }
      {(!props.selectedMenu.isSetMeal ||
        (props.selectedMenu.isSetMeal && props.selectedMenu.optionArray.length > 0 &&
          props.selectedMenu.optionArray[0].optionType != 5)) &&
        <PriceContainer themeContext={themeContext} hasCustomer={customer && pbTitle}>
          {`${pbTitle ? "ORI: " : ""}${props.currencySymbol} ${priceHolder}`}
        </PriceContainer>
      }
      {(typeof props.selectedMenu.pricebook != 'undefined' && Object.keys(props.selectedMenu.pricebook).length > 0) &&
        <PriceBookContainer themeContext={themeContext}>
          {`${pbTitle}: ${props.currencySymbol} ${priceCompareHolder}`}
        </PriceBookContainer>
      }
    </PriceWapper>
  );
};

const IconButton = styled.div`
  position: absolute;
  right: 16px;
  top: 16px;
  background: white;
  display:flex;
  z-index: 99;
  border-radius: 18px;
  box-shadow: 1px 1px 3px rgba(0,0,0,3.5);
`;

const PriceWapper = styled.div`
  display: grid;
  color: ${(props) => props.themeContext.fontPrice};
  font-weight: 400;
  font-family: ${(props) => props.themeContext.fontFamily};
  margin-top: 5px;
  white-space: nowrap;
  align-content: center;
  margin-right: 16px;
  text-align: right;
`;
const PriceCompareContainer = styled.div`
  text-decoration: line-through;
  font-size: 14px;
 
`;
const PriceContainer = styled.div`
  
  font-size: ${(props) => (props.hasCustomer ? "14" : "16")}px;
  text-decoration: ${(props) => (props.hasCustomer ? "line-through" : "none")};
`;
const PriceBookContainer = styled.div`
  font-size: 16px;
`;

const ItemDisplay = styled.div`
  display: block;
  text-align: center;
  
  justify-content: center;
  align-items: center;
  margin-bottom: 10px;
  height:  ${(props) => props.theme == 3 || props.isEdit ? props.height + "px" : "300px"};
  max-height: 350px;
  //Adjust the image height to be adjusted based on the screen size at a later date.
  background-image: url(${(props) => props.image});
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  animation: itemPic .15s;
  animation-timing-function: ease-out;
  @keyframes itemPic {
    0%  {
      opacity:0.3;
      transform: translateY(-40px);
    }
    
    100%  {
      opacity:1;
      transform: translateY(0px);
    }
    
  }
`;

const OptionsDisplay = styled.div`
  display: block;
  text-align: center;
  background-color: ${(props) => props.themeContext.backgroundColor};
  align-content: center;
  justify-content: center;
  align-items: center;
  border-top-right-radius: 20px;
  margin-top: ${(props) => props.theme == 3 || props.isEdit ? "0px" : "-50px"};
  bottom: 0px;
  //Temp Height, remove at commit, adjust min height, maybe based on screensize??
  //Probably going to require a tablet build at a certain point.
  min-height: 80 %;
 
  animation: itemTxt .2s;
  animation-timing-function: ease-out;
  @keyframes itemTxt {
    0%  {
      transform: translateY(20px);
    }
    
    100%  {
      transform: translateY(0px);
    }
    
  }
  
`;

const MenuInfoHeaderContainer = styled.div`
  display: flex;
  flex-direction: row;
  width: 100 %;
`;

const TitleContainer = styled.div`
  display: grid;
  flex-grow:1;
  min-height: 36px;
  color: ${(props) => props.themeContext.fontPrimary};

  background-color: ${(props) => props.themeContext.backgroundColor};
  font-family: ${(props) => props.themeContext.fontFamily};
  font-size: ${(props) => {
    if (props.itemTitle.length > 20) {
      return 16;
    } else if (props.itemTitle.length > 10) {
      return 16;
    } else {
      return 16;
    }
  }
  }px;
  margin-top: 7px;
  align-content: center;
  margin-left: 5px;
  font-weight: bold;
  text-align: left;
`;
const DescriptionContainer = styled.div`
  display: flex;
  flex-direction: row;
  font-size: 14px;
  font-weight: 300;
  margin-top: 5px;
  margin-left: 5px;
  text-align: left;
  white-space: pre-wrap;
`;
